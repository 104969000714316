import React from 'react';
import Intro from './intro';
import Content from './content';
import Header from './header';
import Banner from './banner';
import Footer from './footer';

const App = () => {
    return (
    <div className="App">
      <Header />
      <Banner />
      <Intro />
      <Content />
      <Footer />
    </div>
    );
  }

export default App;