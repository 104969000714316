import React from 'react';

const Intro = () => {
    return (
        <div className="intro">
            <p>Si Wazzou est indisponible en raison de travaux de maintenance ou de circonstances imprévues, nous vous en informerons sur cette page.</p>Wazzou est en constante amélioration. À cette fin, nous procédons à des mises à jour régulières. Pendant ces mises à jour, Wazzou restera disponible. Parfois, Wazzou sera temporairement indisponible pour des mises à jour ou des modifications plus importantes mais ces informations seront toujours annoncées à l’avance. Afin de gêner le moins possible les utilisateurs, nous programmons les travaux de maintenance, de préférence la nuit, le week-end ou les jours fériés.
        </div>
    )
}

export default Intro;