import React from 'react';
import vanin from './vanin.png';

const Header = () => {
    return (
        <div className="top">
            <a href="https://www.vanin-fondamental.be/nos-matieres/wazzou/" target="_blank" rel="noopener noreferrer"><img src={vanin} alt="Bingel statuspagina" /></a>
        </div>
    )
}

export default Header;