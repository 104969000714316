import React from 'react';

const year = new Date().getFullYear();

const Footer = () => {
    return (
        <footer className="footer">
            COPYRIGHT © {year} | POWERED BY VAN IN
        </footer>
    )
}

export default Footer;