import React from 'react';
import banner from './banner.jpeg';

const Banner = () => {
    return (
        <div className="banner">
            <img src={banner} alt="Bingel" />
            <h2>Wazzou - Statut de la platforme</h2>
        </div>
    )
}

export default Banner;